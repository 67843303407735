<template>
  <!-- Begin Page Content -->
  <div class="" v-show="permissions.includes('leaves.write')">
    <div class="card shadow-sm border-0 my-4 mb-3">
      <div class="card-body">
        <div
          class="d-flex align-items-center flex-md-nowrap flex-wrap justify-content-between gap-md-0 gap-2 px-2"
          v-if="firstTable"
        >
          <h4 class="m-0 font-weight-bold text-primary order-md-0 order-1">
            Leaves
          </h4>
          <div class="order-3 order-md-0 mt-md-0 mt-2 mx-auto px-md-0 px-2">
            <div class="btn-group" role="group">
              <router-link
                :to="{ name: 'LeavesApplication' }"
                class="btn btn-sm"
                :class="
                  this.$route.name == 'LeavesApplication'
                    ? 'btn-primary'
                    : 'btn-outline-primary bg-white text-primary'
                "
                v-if="permissions.includes('leaves.read')"
              >
                Leaves
              </router-link>
              <router-link
                :to="{ name: 'AddCreditDebit' }"
                class="btn btn-sm"
                :class="
                  this.$route.name == 'AddCreditDebit'
                    ? 'btn-primary'
                    : 'btn-outline-primary bg-white text-primary'
                "
                v-if="permissions.includes('credit_debit.read')"
              >
                Credit/Debit
              </router-link>
              <router-link
                :to="{ name: 'LeavesStatus' }"
                class="btn btn-sm"
                :class="
                  this.$route.name == 'LeavesStatus'
                    ? 'btn-primary'
                    : 'btn-outline-primary bg-white text-primary'
                "
                v-if="permissions.includes('leaves_status.report')"
              >
                Status
              </router-link>
            </div>
          </div>
          <router-link
            class="btn btn-primary btn-sm order-md-0 order-2"
            :to="{ name: 'AddLeaveApplication' }"
            v-if="
              permissions.includes('leaves.read') &&
              permissions.includes('leaves.add')
            "
          >
            Apply
          </router-link>
          <!-- <button
            class="btn btn-primary btn-sm order-md-0 order-2"
            @click="leaveModalHandler('add', undefined, 'your')"
            v-if="
              permissions.includes('leaves.read') &&
              permissions.includes('leaves.add')
            "
          >
            Apply
          </button> -->
        </div>
        <!-- <div
          class="my-1 d-flex justify-content-center align-items-center"
          v-if="firstTable"
        >
        </div> -->
        <div class="p-2">
          <div
            class="d-sm-flex align-items-center justify-content-between py-3"
            v-if="firstTable"
          >
            <h5 class="text-md-start text-center text-primary">Your Leaves</h5>
          </div>
          <div
            v-if="permissions.includes('leaves.read') && firstTable"
            class="mb-5"
          >
            <vue-good-table
              mode="remote"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
              :totalRows="Filtered_Records_First"
              :isLoading.sync="isLoadingYour"
              :sort-options="{
                enabled: true,
              }"
              :pagination-options="{
                enabled: true,
                perPageDropdown: perpage_list,
              }"
              :rows="your_leave_list_rows"
              :columns="your_leave_list_column"
              styleClass="vgt-table"
            >
              <template slot="table-row" scope="props">
                <span v-if="props.column.label == 'Action'">
                  <!-- <div v-if="new Date(props.row.to_date).getMonth() >= current_month"> -->
                  <div v-if="props.row.stage == 'applied'">
                    <div
                      class="d-flex justify-content-center align-items-center gap-3"
                    >
                      <button
                        class="btn-view"
                        @click="approve_leave(props)"
                        v-if="props.row.user_id != currentUser.id"
                      >
                        <i class="fa fa-check fs-5"></i>
                      </button>
                      <!-- update -->
                      <!-- <button
                        class="btn-pencil"
                        v-if="props.row.user_id == current_user_id"
                        @click="
                          leaveModalHandler(
                            'update',
                            props.row.data,
                            'your',
                            'not_approved'
                          )
                        "
                      >
                        <i class="fa fa-pencil"></i>
                      </button> -->
                      <router-link
                        :to="'/update-leave-application/' + props.row.id"
                        v-if="props.row.user_id == currentUser.id"
                        class="btn-pencil"
                      >
                        <i class="fa fa-pencil fs-5"></i>
                      </router-link>
                      <button
                        class="btn-trash"
                        v-if="props.row.user_id != currentUser.id"
                        @click="reject_leave(props.row.id)"
                      >
                        <i class="fa fa-times fs-5"></i>
                      </button>
                      <button
                        class="btn-trash"
                        v-if="props.row.user_id == currentUser.id"
                        @click="delete_leave(props.row.id)"
                      >
                        <i class="fa fa-trash fs-5"></i>
                      </button>
                    </div>
                  </div>
                  <div v-else>
                    <span>{{ props.row.stage }}</span>
                  </div>
                </span>
              </template>
              <template
                slot="column-filter"
                slot-scope="{ column, updateFilters }"
              >
                <!-- <span v-if="column.field == 'no_of_days'">
                <input type="" :typeable="true" placeholder="Days"
                  @input="(e) => updateFilters(column,e)"/>
              </span> -->
                <span v-if="column.field == 'from_date'">
                  <datepicker
                    :typeable="true"
                    :format="formateDate"
                    placeholder="yyyy-mm-dd"
                    @input="
                      (e) =>
                        updateFilters(
                          column,
                          e
                            ? e.toISOString().substring(0, 10)
                            : updateFilters(column, '')
                        )
                    "
                  />
                </span>
                <span v-else-if="column.field == 'to_date'">
                  <datepicker
                    :typeable="true"
                    :format="formateDate"
                    placeholder="yyyy-mm-dd"
                    @input="
                      (e) =>
                        updateFilters(
                          column,
                          e
                            ? e.toISOString().substring(0, 10)
                            : updateFilters(column, '')
                        )
                    "
                  />
                </span>
              </template>
              <template slot="pagination-bottom" slot-scope="props">
                <CustomPagination
                  class="p-2"
                  :pageTabs="4"
                  :total="props.total"
                  :pageChanged="props.pageChanged"
                  :perPageChanged="props.perPageChanged"
                  :serverParams="serverParams"
                ></CustomPagination>
              </template>
            </vue-good-table>
            <!-- <div class="mt-3">
            <button class="btn btn-primary" @click="pdfDownload">Pdf</button>
            <button class="btn btn-primary ml-3" @click="excelDownload">
              Excel
            </button>
          </div> -->
          </div>
          <div v-else-if="firstTable" class="container-fluid">
            <div class="row text-center">
              <div class="col">
                <b class="text"><h3>Empty</h3></b>
              </div>
            </div>
          </div>
          <div
            class="d-flex justify-content-between align-items-center flex-md-row flex-column gap-2"
            v-if="secondTable"
          >
            <h5 class="team-text text-primary m-0" :class="tag ? tag : ''">
              Teams Member Leaves
            </h5>
            <multiselect
              v-if="secondTable && !firstTable"
              style="max-width: 200px"
              @input="FilterApply()"
              v-model="dashboard_log"
              :options="options"
              placeholder="Select one or more User"
              :searchable="false"
              :close-on-select="false"
              :show-labels="false"
              id="dashboard"
            >
              <template
                slot="selection"
                slot-scope="{ values, search, isOpen }"
              ></template>
            </multiselect>

            <multiselect
              v-if="firstTable && secondTable"
              style="max-width: 200px"
              @input="FilterApply()"
              v-model="page_log"
              :options="options"
              placeholder="Select one or more User"
              :searchable="false"
              :close-on-select="false"
              :show-labels="false"
            >
              <template
                slot="selection"
                slot-scope="{ values, search, isOpen }"
              ></template>
            </multiselect>
          </div>
          <div class="mt-4" v-if="permissions.includes('leaves.read')">
            <vue-good-table
              mode="remote"
              @on-page-change="onPageChangeSecond"
              @on-sort-change="onSortChangeSecond"
              @on-column-filter="onColumnFilterSecond"
              @on-per-page-change="onPerPageChangeSecond"
              :total-rows="Filtered_Records_Second"
              :isLoading.sync="isLoadingTeam"
              :sort-options="{
                enabled: true,
              }"
              :pagination-options="{
                enabled: true,
                perPageDropdown: perpage_list,
              }"
              :rows="team_mates_leave_rows"
              :columns="team_mates_leave_list_column"
              styleClass="vgt-table"
            >
              <template slot="table-row" scope="props">
                <span v-if="props.column.label == 'User'">
                  <span
                    v-if="
                      props.row.first_name !== null &&
                      props.row.middle_name !== null &&
                      props.row.last_name !== null
                    "
                  >
                    {{
                      props.row.first_name +
                      " " +
                      props.row.middle_name +
                      " " +
                      props.row.last_name
                    }}
                  </span>
                  <span
                    v-else-if="
                      props.row.first_name !== null &&
                      props.row.middle_name == null &&
                      props.row.last_name !== null
                    "
                  >
                    {{ props.row.first_name + " " + props.row.last_name }}
                  </span>
                  <span
                    v-else-if="
                      props.row.first_name !== null &&
                      props.row.middle_name !== null &&
                      props.row.last_name == null
                    "
                  >
                    {{ props.row.first_name + " " + props.row.middle_name }}
                  </span>
                  <span v-else>
                    {{ props.row.first_name }}
                  </span>
                </span>
                <span v-else-if="props.column.label == 'Action'">
                  <!-- <div v-if="new Date(props.row.from_date).getMonth() >= current_month"> -->
                  <div v-if="props.row.stage == 'applied'">
                    <div
                      class="d-flex justify-content-center align-items-center gap-3 p-2"
                    >
                      <button
                        class="btn-view"
                        v-if="props.row.user_id != current_user_id"
                        @click="approve_leave(props)"
                      >
                        <i class="fa fa-check fs-5"></i>
                      </button>
                      <button
                        class="btn-pencil"
                        v-if="props.row.user_id == current_user_id"
                      >
                        <i class="fa fa-pencil fs-5"></i>
                      </button>
                      <button
                        class="btn-trash"
                        @click="reject_leave(props.row.id)"
                        v-if="props.row.user_id != current_user_id"
                      >
                        <i class="fa fa-times fs-5"></i>
                      </button>
                      <button
                        class="btn-trash"
                        @click="delete_leave(props.row.id)"
                        v-if="props.row.user_id == current_user_id"
                      >
                        <i class="fa fa-trash fs-5"></i>
                      </button>
                    </div>
                  </div>
                  <div v-else>
                    <span>{{ props.row.stage }}</span>
                    <button
                      v-if="
                        permissions.includes('approvedleaves.update') &&
                        props.row.stage == 'approved'
                      "
                      class="btn btn-link p-0"
                      data-toggle="modal"
                      data-target="#exampleModalCenter"
                      @click="leaveModalHandler('update', props.row, 'team')"
                    >
                      update
                    </button>
                    <button
                      v-if="
                        permissions.includes('approvedleaves.delete') &&
                        props.row.stage == 'approved'
                      "
                      class="btn btn-link p-0"
                      @click="delete_leave(props.row.id)"
                    >
                      delete
                    </button>
                  </div>
                </span>
              </template>
              <template
                slot="column-filter"
                slot-scope="{ column, updateFilters }"
              >
                <!-- <span v-if="column.field == 'no_of_days'">
                <input type="" :typeable="true" placeholder="Days"
                  @input="(e) => updateFilters(column,e)"/>
              </span> -->
                <span v-if="column.field == 'from_date'">
                  <datepicker
                    :typeable="true"
                    :format="formateDate"
                    placeholder="yyyy-mm-dd"
                    @input="
                      (e) =>
                        updateFilters(
                          column,
                          e
                            ? e.toISOString().substring(0, 10)
                            : updateFilters(column, '')
                        )
                    "
                  />
                </span>
                <span v-else-if="column.field == 'to_date'">
                  <datepicker
                    :typeable="true"
                    :format="formateDate"
                    placeholder="yyyy-mm-dd"
                    @input="
                      (e) =>
                        updateFilters(
                          column,
                          e
                            ? e.toISOString().substring(0, 10)
                            : updateFilters(column, '')
                        )
                    "
                  />
                </span>
                <span v-else-if="column.field == 'no_of_days'">
                  <input
                    type="number"
                    min="0"
                    class="form-control rounded-1"
                    style="height: 25.47px; font-size: 14px"
                    placeholder="No of days"
                    @input="(e) => updateFilters(column, e.target.value)"
                  />
                </span>
              </template>
              <template slot="pagination-bottom" slot-scope="props">
                <CustomPagination
                  class="p-2"
                  :pageTabs="4"
                  :total="props.total"
                  :pageChanged="props.pageChanged"
                  :perPageChanged="props.perPageChanged"
                  :serverParams="serverParamsSecond"
                ></CustomPagination>
              </template>
            </vue-good-table>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="leaveModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="card-header modal-header">
            <h3
              class="mb-0"
              id="exampleModalLongTitle"
              v-text="modal_title"
            ></h3>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <Leaves_popupVue
              v-if="modal_title == 'Update Leave'"
              :leave_data="modal_data"
              @close_modal="closeModal()"
            >
            </Leaves_popupVue>
            <Leaves_popupVue
              v-if="modal_title == 'Apply Leave'"
              @close_modal="closeModal()"
            ></Leaves_popupVue>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Leaves_popupVue from "./Leaves_popup.vue";
import user from "@/ajax/user";
import { mapState, mapActions } from "vuex";
import moment from "moment";
moment.locale("en");
import Datepicker from "vuejs-datepicker";
import myMixin from "@/mixins/Permission";
import CustomPagination from "../../components/CustomPagination.vue";
// import VueSweetalert2 from "vue-sweetalert2";
// import "sweetalert2/dist/sweetalert2.min.css";
// import Vue from "vue";

// Vue.use(VueSweetalert2);

import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { jsontoexcel } from "vue-table-to-excel";

export default {
  name: "Leaves",
  mixins: [myMixin],
  components: { Datepicker, Leaves_popupVue, CustomPagination },
  data() {
    return {
      siteLogReq: {
        site_name: "leaves",
      },
      current_date: "",
      current_user_id: "",
      currentPage: "",
      isLoadingYour: false,
      isLoadingTeam: false,
      reqObj: "",
      your_leave_list_column: [
        {
          label: "No Of Days",
          field: "no_of_days",
          sortable: true,
          filterable: true,
          thClass: "custom-th",
          tdClass: "custom-td",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "no of days", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilter2, //custom filter function that
            // trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Leave Type",
          field: "formatted_leave_type",
          sortable: true,
          filterable: true,
          thClass: "custom-th",
          tdClass: "custom-td",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "leaves", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            // filterDropdownItems: ["full", "half"], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilter2, //custom filter function that
            // trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "From Date",
          field: "from_date",
          sortable: true,
          filterable: true,
          formatFn: this.formatTime,
          thClass: "custom-th",
          tdClass: "custom-td",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "To Date", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilter2, //custom filter function that
            // trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "To Date",
          field: "to_date",
          sortable: true,
          filterable: true,
          formatFn: this.formatTime,
          thClass: "custom-th",
          tdClass: "custom-td",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "To Date", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilter2, //custom filter function that
            // trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Reason",
          field: "reason",
          sortable: true,
          filterable: true,
          thClass: "custom-th",
          tdClass: "custom-td",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "reason", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilter2, //custom filter function that
            // trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Action",
          field: "action",
          sortable: false,
          thClass: "custom-th disable-sorting",
          tdClass: "custom-td",
        },
      ],
      team_mates_leave_list_column: [
        {
          label: "User",
          field: "user_name",
          sortable: true,
          thClass: "custom-th",
          tdClass: "custom-td",
          filterable: true,
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "user", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilterSecond, //custom filter function that
            // trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "No Of Days",
          field: "no_of_days",
          sortable: true,
          thClass: "custom-th",
          tdClass: "custom-td",
          filterable: true,
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "no of days", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilterSecond, //custom filter function that
            // trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Leave Type",
          // field: "formatted_leave_type",
          field: "formatted_leave_type",
          sortable: true,
          thClass: "custom-th",
          tdClass: "custom-td",
          filterable: true,
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "leaves", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilterSecond, //custom filter function that
            // trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "From Date",
          field: "from_date",
          sortable: true,
          thClass: "custom-th",
          tdClass: "custom-td",
          filterable: true,
          formatFn: this.formatTime,
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "To Date", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilterSecond, //custom filter function that
            // trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "To Date",
          field: "to_date",
          sortable: true,
          thClass: "custom-th",
          tdClass: "custom-td",
          filterable: true,
          formatFn: this.formatTime,
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "To Date", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilterSecond, //custom filter function that
            // trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Reason",
          field: "reason",
          sortable: true,
          thClass: "custom-th",
          tdClass: "custom-td",
          filterable: true,
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "reason", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilterSecond, //custom filter function that
            // trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Action",
          field: "action",
          sortable: false,
          thClass: "disable-sorting custom-th",
          tdClass: "custom-td",
        },
      ],
      your_leave_list_rows: [],
      team_mates_leave_rows: [],

      totalRecordsfirsttable: 0,
      totalRecordsSecondTable: 0,
      Filtered_Records_First: 0,
      Filtered_Records_Second: 0,
      serverParams: {
        columnFilters: {},
        sort: [
          {
            field: "",
            type: "asc",
          },
        ],
        page: 1,
        perpage: 100,
        setcurrentPage: 1,
      },
      serverParamsSecond: {
        columnFilters: {
          leave_stage: null,
        },
        sort: [
          {
            field: "",
            type: "asc",
          },
        ],
        page: 1,
        perpage: 100,
      },
      displayPageTask: 7,
      jumpToPageTask: 0,
      pageCompareTask: 0,

      showDialog: false,

      json: {
        dataSource: [],
        head: ["No of Days", "Leave Type", "From Date", "To Date"],
        // fileName:"_Attendance.xlsx"
        fileName: "",
      },
      edited: [],
      current_month: new Date().getMonth(),
      page_log: "All",
      dashboard_log: "Not Approved",
      options: ["All", "Not Approved", "Approved", "Rejected", "Deleted"],
      permissions: window.localStorage.getItem("permissions"),
      // user_role:JSON.parse(localStorage.getItem('user_role')),
      modal_title: null,
      modal_data: null,
      isFor: null,
      status: null,
      perpage_list: [100, 200, 300, 400, 500, "All"],
    };
  },
  computed: {
    ...mapState("userStore", ["currentUser"]),
  },
  mounted() {
    this.setCurrentPage("Attendance");
    $("#leaveModal").on("hidden.bs.modal", this.hideModalEventHandler);
    this.getUserPermissions();
    if (this.permissions.includes("leaves.write")) {
      this.getYourLeaveList();
      this.getTeamMatesLeaveList();
      this.getCurrentUser();
      this.current_date = moment(new Date()).format("YYYY-MM-DDThh:mm:ss");
    }
  },
  created() {
    user.createSiteUsage(this.siteLogReq);
  },
  methods: {
    ...mapActions("userStore", ["setCurrentUser", "logoutUser"]),
    ...mapActions("common", ["setCurrentPage"]),
    closeModal() {
      // console.log("close modal");
      this.getTeamMatesLeaveList();
      $("#leaveModal").modal("hide");
    },
    hideModalEventHandler() {
      this.modal_title = null;
      this.modal_data = null;
      this.status = null;
      $("#leaveModal").modal("hide");
    },
    leaveModalHandler(action, data = null, isFor) {
      if (action == "add") {
        this.isFor = isFor;
        this.modal_title = "Apply Leave";
        $("#leaveModal").modal("show");
      } else if (action == "update") {
        this.isFor = isFor;
        this.modal_title = "Update Leave";
        this.modal_data = data;
        $("#leaveModal").modal("show");
      } else {
        $("#leaveModal").modal("hide");
        this.isFor == "your"
          ? this.getYourLeaveList
          : this.getTeamMatesLeaveList();
      }
    },
    //update modals
    modalDataTransfer(data) {
      this.modal_data = data;
      // console.log("base---- ",this.modal_data);
      $("#leaveModal").modal("show");
    },

    //====Pdf  and excel download
    dateFormator(date) {
      const temp = new Date(date);
      return temp.toISOString().substring(0, 10);
    },
    newJson() {
      this.your_leave_list_rows.forEach((obj, index) => {
        this.edited.push([]);
        this.edited[index].push(obj.no_of_days);
        this.edited[index].push(obj.leave_type);
        this.edited[index].push(this.dateFormator(obj.from_date));
        this.edited[index].push(this.dateFormator(obj.to_date));
      });
    },
    pdfDownload() {
      const doc = new jsPDF();
      this.newJson();
      autoTable(doc, {
        head: [this.json.head],
        body: this.edited,
        styles: { fontSize: 8, halign: "center", valign: "middle" },
      });
      let curr_date = moment(this.searched_date_input_box).format("yyyy-MM-DD");
      doc.save(curr_date + "_Attendance.pdf");
    },
    excelDownload() {
      let customData = {};
      let temp = [];
      // this.json.dataSource = this.edited;
      this.newJson();
      const arr = this.edited;
      this.edited.forEach((arr) => {
        customData = {
          "No of Days": arr[0],
          "Leave Type": arr[1],
          "From Date": arr[2],
          "To Date": arr[3],
        };
        temp.push(customData);
        this.json.dataSource = temp;
        const { dataSource, head, fileName } = this.json;
        var curr_date = moment(this.searched_date_input_box).format(
          "yyyy-MM-DD"
        );
        this.json["fileName"] = curr_date + "_Attendance.xlsx";
        jsontoexcel.getXlsx(dataSource, head, fileName);
      });
    },
    // first table functions start
    getYourLeaveList() {
      var fields = {
        filters: this.serverParams.columnFilters,
        messageData: this.message,
        sort: this.serverParams.sort,
        page: this.serverParams.page,
        perpage: this.serverParams.perpage,
      };
      // console.log("search",fields);
      user.getYourLeaveList(fields).then((result) => {
        this.your_leave_list_rows = [];
        this.your_leave_list_rows = result.data.data.data;
        this.Filtered_Records_First = result.data.data.f_total;
        this.totalRecordsfirsttable = result.data.data.total;
      });
    },
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },
    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.getYourLeaveList();
    },
    onPerPageChange(params) {
      this.updateParams({ perpage: params.currentPerPage });
      this.serverParams.page = 1;
      this.getYourLeaveList();
    },
    onSortChange(params) {
      this.updateParams({
        sort: [
          {
            type: params[0].type,
            field: params[0].field,
          },
        ],
      });
      this.getYourLeaveList();
    },
    onColumnFilter(params) {
      //remove empty fields
      var newObj = { columnFilters: {} };
      Object.keys(params.columnFilters).forEach(function (key) {
        if (params.columnFilters[key] !== "")
          newObj.columnFilters[key] = params.columnFilters[key];
      });
      this.updateParams(newObj);
      this.serverParams.page = 1;
      this.getYourLeaveList();
    },
    // first table functions end

    // second table functions start
    getTeamMatesLeaveList() {
      this.serverParamsSecond.columnFilters["leave_stage"] =
        this.firstTable == true ? this.page_log : this.dashboard_log;
      var fields = {
        filters: this.serverParamsSecond.columnFilters,
        messageData: this.message,
        sort: this.serverParamsSecond.sort,
        page: this.serverParamsSecond.page,
        perpage: this.serverParamsSecond.perpage,
      };
      // console.log("search",fields);
      user.getTeamMatesLeaveList(fields).then((result) => {
        this.team_mates_leave_rows = [];
        this.team_mates_leave_rows = result.data.data.data;
        this.Filtered_Records_Second = result.data.data.f_total;
        this.totalRecordsSecondTable = result.data.data.total;
      });
    },
    updateParamsSecond(newProps) {
      this.serverParamsSecond = Object.assign(
        {},
        this.serverParamsSecond,
        newProps
      );
    },
    onPageChangeSecond(params) {
      this.updateParamsSecond({ page: params.currentPage });
      this.getTeamMatesLeaveList();
    },
    onPerPageChangeSecond(params) {
      this.updateParamsSecond({ perpage: params.currentPerPage });
      this.serverParamsSecond.page = 1;
      this.getTeamMatesLeaveList();
    },
    onSortChangeSecond(params) {
      this.updateParamsSecond({
        sort: [
          {
            type: params[0].type,
            field: params[0].field,
          },
        ],
      });
      this.getTeamMatesLeaveList();
    },
    onColumnFilterSecond(params) {
      //remove empty fields
      var newObj = { columnFilters: {} };
      Object.keys(params.columnFilters).forEach(function (key) {
        if (params.columnFilters[key] !== "")
          newObj.columnFilters[key] = params.columnFilters[key];
      });
      this.updateParamsSecond(newObj);
      this.serverParamsSecond.page = 1;
      this.getTeamMatesLeaveList();
    },
    // second table functions end
    formateDate(date) {
      return moment(date).format("yyyy-MM-DD");
    },

    getCurrentUser() {
      user.getCurrentUser().then((result) => {
        this.setCurrentUser(result.data);
        this.current_user_id = result.data.id;
      });
    },

    formatTime(value) {
      return moment(value).format("yyyy-MM-DD");
    },

    FilterApply() {
      this.serverParamsSecond = Object.assign({}, this.serverParamsSecond, {
        page: 1,
        perpage: 100,
      });
      this.$nextTick(() => this.getTeamMatesLeaveList());
    },
    approve_leave(props) {
      // this.showDialog = true;
      let this2 = this;
      this.$swal({
        html: `<div class='p-5 overflow-hidden h-100'>
              <div class='spinner-border text-primary' style='width:4rem;height:4rem'></div>
              <h3 class='mt-5'>Approving....</h3>
            </div>`,
        showConfirmButton: false,
        allowOutsideClick: false,
        didOpen() {
          let time = 0;
          let time_id = setInterval(() => {
            time++;
          }, 1000);

          setTimeout(() => {
            if (time >= 15) {
              clearInterval(time_id);
              this.$swal({
                title: "Slow internet connection",
                icon: "error",
                timer: 2500,
              });
            }
            if (navigator.onLine && time < 15) {
              user
                .approve_leaves(props.row.id)
                .then((result) => {
                  if (result.data.code == 200) {
                    Swal.fire({
                      title: "Approved succesfully...",
                      text: "Leave approved succesfully",
                      icon: "success",
                      timer: 2500,
                    }).then(() => {
                      this2.getTeamMatesLeaveList();
                    });
                  } else {
                    Swal.fire({
                      title: "Failed to approve",
                      text: "Failed to approve leave",
                      icon: "error",
                      timer: 2500,
                    });
                  }
                })
                .catch(() => {
                  Swal.fire({
                    title: "Something went wrong",
                    text: "Unable to perform the action",
                    icon: "error",
                    timer: 2500,
                  });
                });
            } else {
              Swal.fire({
                title: "No internet connection",
                text: "Please check your internet connecction",
                icon: "error",
                timer: 2500,
              });
            }
          }, 1000);
        },
      });
    },

    reject_leave(id) {
      let this2 = this;
      Swal.fire({
        title: "Reject",
        text: "Are you sure you want to reject?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "",
        confirmButtonText: "Yes, reject it!",
        closeOnConfirm: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.$swal({
            html: `<div class='p-5 overflow-hidden h-100'>
              <div class='spinner-border text-primary' style='width:4rem;height:4rem'></div>
              <h3 class='mt-5'>Rejecting....</h3>
            </div>`,
            showConfirmButton: false,
            allowOutsideClick: false,
            didOpen() {
              let time = 0;
              let time_id = setInterval(() => {
                time++;
              }, 1000);

              setTimeout(() => {
                if (time >= 15) {
                  clearInterval(time_id);
                  this.$swal({
                    title: "Slow internet connection",
                    icon: "error",
                    timer: 2500,
                  });
                }
                if (navigator.onLine && time < 15) {
                  user
                    .reject_leave(id)
                    .then((result) => {
                      if (result.data.code == 200) {
                        Swal.fire({
                          title: "Rejected successfully...",
                          text: "Leave rejected successfully",
                          icon: "success",
                          timer: 2500,
                        }).then(() => this2.getTeamMatesLeaveList());
                      } else {
                        Swal.fire({
                          title: "Failed to reject...",
                          text: "Failed to reject the leave",
                          icon: "error",
                          timer: 2500,
                        });
                      }
                    })
                    .catch(() => {
                      Swal.fire({
                        title: "Something went wrong",
                        text: "Unable to reject the leave",
                        icon: "error",
                        timer: 2500,
                      });
                    });
                } else {
                  Swal.fire({
                    title: "No internet connection",
                    text: "Please check your internet connecction",
                    icon: "error",
                    timer: 2500,
                  });
                }
              }, 1000);
            },
          });
        }
      });
    },
    delete_leave(id) {
      this.$swal({
        title: "Delete",
        text: "Are you sure you want to delete?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "",
        confirmButtonText: "Yes, Delete it!",
        closeOnConfirm: true,
        icon: "question",
      }).then((result) => {
        if (result.isConfirmed) {
          // this.$swal("Deleted", "You successfully delete this Leave", "success");
          user.delete_leave(id).then((result) => {
            // this.$router.push({name: 'LeavesApplication'});
            // alert("deleted successfully");
            Swal.fire("Success", "Deleted Successfully.....!", "success");
            this.getYourLeaveList();
            this.getTeamMatesLeaveList();
          });
          // window.location.reload();
        }
      });
    },
  },
  props: {
    tag: {
      type: String,
      default: null,
    },
    firstTable: {
      default: true,
      type: Boolean,
    },
    secondTable: {
      default: true,
      type: Boolean,
    },
  },
};
</script>
