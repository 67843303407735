<template>
  <header
    class="d-flex justify-content-between border-0 border-danger align-items-center bg-white py-3 shadow-sm"
  >
    <!-- sidebar -->
    <div class="border-0 border-warning">
      <button
        class="btn btn-white fs-4 py-2 ps-3 d-lg-none d-block"
        type="button"
        data-bs-toggle="offcanvas"
        data-bs-target="#sideBar"
      >
        <i class="fa fa-bars text-dark"></i>
      </button>

      <div
        class="offcanvas-start offcanvas-lg position-fixed top-0 p-0 bg-transparent"
        style="width: max-content; height: 100vh; z-index: 1050"
        tabindex="-1"
        id="sideBar"
      >
        <side-bar></side-bar>
      </div>
    </div>
    <!-- <div class="border-0 border-danger ms-md-3 ms-5">
      <div class="input-group">
        <span class="input-group-text">
          <i class="fa fa-search"></i>
        </span>
        <input class="form-control" placeholder="Search here ..." />
      </div>
    </div> -->
    <!-- Other Stuff -->

    <div class="border-0 hstack flex-wrap gap-3 border-success mx-md-0 mx-auto">
      <span class="notify d-md-block d-none">
        <i class="notiflcation-img opt-notification me-2"></i>
      </span>

      <button
        v-if="!currentUser.state || currentUser.state == null"
        class="btn btn-primary btn-sm p-0 px-2"
        @click="updateUser('start')"
      >
        Start
      </button>
      <button
        v-if="currentUser.state == 'work'"
        class="btn btn-primary btn-sm p-0 px-2"
        @click="updateUser('stop')"
      >
        Stop
      </button>
      <button
        v-if="currentUser.state == 'break' || currentUser.state == 'lunch'"
        class="btn btn-primary btn-sm p-0 px-2"
        @click="updateUser('resume')"
      >
        Resume
      </button>
      <small
        v-if="!currentUser.state || currentUser.state == 'stop'"
        class="text-danger"
      >
        <i class="fa fa-circle me-1" style="font-size: 11px"></i>In Active
      </small>
      <small v-if="currentUser.state == 'work'" class="text-success">
        <i class="fa fa-circle me-1" style="font-size: 11px"></i>Active
      </small>
      <small
        v-if="currentUser.state == 'break' || currentUser.state == 'lunch'"
        class="text-danger"
      >
        <i class="fa fa-circle me-1" style="font-size: 11px"></i>On Break
      </small>
      <div class="dropdown p-0 m-0">
        <button
          class="btn btn-white dropdown-toggle border-0 btn-sm"
          type="button"
          id="dropdownMenuButton1"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          @click="TodayTimeSpent()"
        >
          <img
            v-if="img_url"
            :style="{
              height: '26px',
              width: '26px',
              background: `url(${img_url})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
              backgroundPosition: 'center',
              backgroundColor: 'rgba(0,0,0,0.8)',
            }"
            class="rounded-circle ml-auto me-2"
          />
          <i
            v-else
            class="text-primary rounded-circle me-2 fa fa-user-circle-o fs-5 d-inline-block align-middle"
            aria-hidden="true"
          ></i>
          <small class="">{{
            currentUser && currentUser.first_name
              ? currentUser.first_name
              : "UserName"
          }}</small>
        </button>
        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
          <li class="dropdown-item py-1 text-start" @click="userprofile">
            <!-- <router-link
              class="text-dark text-decoration-none"
              tag="a"
              :to="'/prof/' + currentUser.id"
            >
          </router-link> -->
            <span>
              <i class="opt-user me-2"></i>
              <!-- <i class=""></i> -->
              Your Profile
            </span>
          </li>
          <li class="dropdown-item py-1" @click="changePasswdModalHandler">
            <span class="">
              <i class="fa fa-lock me-2"></i>
              Change Password
            </span>
          </li>
          <li class="dropdown-item py-1" @click="emailsettings">
            <span class="">
              <i class="opt-setting me-2"></i>
              Settings
            </span>
          </li>
          <li class="dropdown-item py-1" @click="logout">
            <span class="">
              <i class="opt-logout fa-fw me-2"></i>
              <!-- <b-icon icon="box-arrow-right" style="color:#808080" ></b-icon> -->
              Logout
            </span>
          </li>
          <li
            class="dropdown-item py-1"
            v-if="currentUser.state == 'work'"
            @click="LogOutAndStop"
          >
            <span class="">
              <i class="fa fa-sign-out me-2"></i>
              <!-- <b-icon icon="box-arrow-right" style="color:#000"></b-icon> -->
              Stop at
              {{
                live_time.hour.toLocaleString(undefined, {
                  minimumIntegerDigits: 2,
                }) +
                ":" +
                live_time.minute.toLocaleString(undefined, {
                  minimumIntegerDigits: 2,
                })
              }}
              and logout
            </span>
          </li>
        </ul>
      </div>
    </div>
    <!-- <template>
        <b-modal id="modal-prevent-closing" ref="modal" title="Reset Password" @show="resetModal" @hidden="resetModal" @ok="handleOk">
            <form ref="form" @submit.stop.prevent="checkAndResetPassword">
                <b-form-group label="Old Password" label-for="password" invalid-feedback="Old Password is required" :state="passwordState">
                    <b-form-input id="input-password-old" type="password" v-model="old_password" :state="passwordState" required></b-form-input>
                </b-form-group>
                <b-form-group label="New Password" label-for="password" invalid-feedback="New Password is required" :state="passwordState">
                    <b-form-input id="input-password-new" type="password" v-model="new_password" :state="passwordState" required></b-form-input>
                    <p style="color : #FF0000;">{{passwordError}}</p>
                </b-form-group>
                <b-form-group label="Confirm Password" label-for="password" invalid-feedback="Confirm Password is required" :state="passwordState">
                    <b-form-input id="input-password-confirm" type="password" v-model="confirm_password" :state="passwordState" required></b-form-input>
                </b-form-group>
                    <div id="alert" style="color : #FF0000;" v-if="alert">{{ alert }}</div>
            </form>
        </b-modal>
      </template> -->
    <div
      class="modal fade"
      id="changePasswdModal"
      aria-hidden="true"
      tabindex="-1"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h3 class="text-dark font-weight-bold mb-0">Reset Password</h3>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="handleOk">
              <div class="mb-3">
                <label class="form-label mandatory">Old Password</label>
                <input
                  v-model="old_password"
                  class="form-control"
                  type="password"
                  required
                  placeholder="Enter your old password"
                />
              </div>
              <div class="mb-3">
                <label class="form-label mandatory">New Password</label>
                <input
                  v-model="new_password"
                  class="form-control"
                  type="password"
                  required
                  placeholder="Enter your new password"
                />
                <small class="text-danger" v-if="passwordError">
                  {{ passwordError }}
                </small>
              </div>
              <div class="mb-3">
                <label class="form-label mandatory">Confirm Password</label>
                <input
                  v-model="confirm_password"
                  class="form-control"
                  type="password"
                  required
                  placeholder="Renter yout new password"
                />
                <small id="alert" class="text-danger" v-if="alert">
                  {{ alert }}
                </small>
              </div>
              <div
                class="mb-3 d-flex justify-content-end align-items-center gap-2"
              >
                <button class="btn btn-primary btn-sm">Submit</button>
                <button
                  class="btn btn-outline-secondary btn-sm"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="userRewardModal" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <img src="img/icons8-confetti.gif" />
            <h3 class="text-dark mb-0 mx-auto text-center">
              You Have Earned {{ rewards.length }} Rewards
            </h3>
            <img src="img/icons8-confetti.gif" style="transform: scaleX(-1)" />
          </div>
          <div class="modal-body">
            <div class="row bg-light p-2 text-center">
              <div class="col-4">Reward Name</div>
              <div class="col-4">Earned Points</div>
              <div class="col-4">Total Points</div>
            </div>
            <div
              class="row p-2 text-center"
              v-for="(item, index) in rewards"
              :key="index"
            >
              <div class="col-4" v-text="item.name"></div>
              <div class="col-4" v-text="item.points"></div>
              <div class="col-4" v-text="item.total_points"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="birthday-quotes-modal"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-md modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title text-secondary w-100 text-center">
              Today's Birthdays
            </h6>

            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              v-if="!isModalBlocked"
            ></button>
          </div>
          <div class="modal-body">
            <!-- Carousel -->
            <div
              v-if="birthdayQuotes.length > 0"
              id="carouselExampleAutoplaying"
              class="carousel slide"
              data-bs-ride="carousel"
              data-bs-theme="dark"
              data-bs-interval="1000"
            >
              <div class="carousel-inner">
                <div
                  v-for="(quote, index) in birthdayQuotes"
                  :key="index"
                  class="carousel-item"
                  :class="{ active: index === 0 }"
                >
                  <div
                    class="position-relative d-inline-block"
                    style="overflow: hidden"
                  >
                    <img
                      v-if="quote.image_url"
                      :src="quote.quote_image_url"
                      alt="Birthday Quote"
                      class="d-block w-100"
                      style="object-fit: contain"
                    />
                  </div>
                  <div class="mt-2 text-center p-2 bg-white">
                    <h5
                      class="text-shadow text-secondary"
                      @click="quote.showFullText = !quote.showFullText"
                    >
                      {{ getQuoteText(quote) }}
                    </h5>
                  </div>
                </div>
              </div>
              <div v-if="birthdayQuotes.length > 1">
                <button
                  class="carousel-control-prev mb-5"
                  type="button"
                  data-bs-target="#carouselExampleAutoplaying"
                  data-bs-slide="prev"
                >
                  <span
                    class="carousel-control-prev-icon"
                    aria-hidden="true"
                  ></span>
                  <span class="visually-hidden">Previous</span>
                </button>
                <button
                  class="carousel-control-next mb-5"
                  type="button"
                  data-bs-target="#carouselExampleAutoplaying"
                  data-bs-slide="next"
                >
                  <span
                    class="carousel-control-next-icon"
                    aria-hidden="true"
                  ></span>
                  <span class="visually-hidden">Next</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>
<script>
import user from "@/ajax/user";
import { mapState, mapActions } from "vuex";
import Vue from "vue";
import SideBar from "@/views/Layouts/Sidebar.vue";
// import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
// import 'bootstrap/dist/css/bootstrap.css'
// import "bootstrap-vue/dist/bootstrap-vue.css";
import myMixin from "@/mixins/Permission";

// Vue.use(BootstrapVue);
// Vue.use(IconsPlugin);
import swal from "sweetalert2";
window.Swal = swal;

export default {
  name: "Header",
  mixins: [myMixin],
  components: {
    SideBar,
  },
  data: function () {
    return {
      reqObj: {
        flag: "",
        user_id: "",
        breakType: "",
        breakTime: null,
      },
      birthdayQuotes: [],
      isModalBlocked: false,
      blockTime: 0,
      //  currentPage: '',
      current_user: "",
      old_password: "",
      new_password: "",
      confirm_password: "",
      passwordState: null,
      passwordError: "",
      alert: "",
      display_logout_pop_up: "",
      img_url: "",
      rewards: [],
    };
  },
  methods: {
    ...mapActions("userStore", ["setCurrentUser", "logoutUser"]),
    userprofile() {
      this.$router
        .push({ name: "Profile", params: { id: this.currentUser.id } })
        .then(() => {
          this.$router.go();
        });
    },
    hideModalEventHandler() {
      this.reward = [];
    },
    getQuoteText(quote) {
      if (quote.showFullText || quote.quote_text.length <= 42) {
        return quote.quote_text;
      }
      return this.truncateText(quote.quote_text, 42);
    },
    truncateText(text, maxLength) {
      return text.length > maxLength
        ? text.substring(0, maxLength) + "..."
        : text;
    },
    //Get Birthdays quotes
    getTodaysQuotes() {
      const params = { is_web: true };
      user
        .getBirthdaysQuotes(params)
        .then((result) => {
          // console.log("Birthday Quotes Response:", quotes);
          const quotes = result.data.data;

          this.birthdayQuotes = quotes.map((quote) => ({
            ...quote,
            showFullText: false,
          }));

          if (quotes.length > 0) {
            if (quotes.length > 1) {
              this.blockTime = quotes.length * 1000;
              this.isModalBlocked = true;
              this.countdown = this.blockTime / 1000;

              let blockModaltimeout = setTimeout(() => {
                this.isModalBlocked = false;
                clearInterval(blockModaltimeout);
              }, this.blockTime);
            } else {
              this.isModalBlocked = false;
            }
            // for carousel autoslide
            let carouselAutoslide = setTimeout(() => {
              const modalElement = $("#birthday-quotes-modal");
              modalElement.modal({
                backdrop: "static",
                keyboard: false,
              });

              modalElement.on("shown.bs.modal", () => {
                $("#carouselExampleAutoplaying").carousel("cycle");
              });

              modalElement.modal("show");
              clearInterval(carouselAutoslide);
            }, 0);
          } else {
            this.AttendanceAlert();
          }
        })
        .catch((error) => {
          console.error("Error fetching birthday quotes:", error);
          this.AttendanceAlert();
        });
    },
    AttendanceAlert() {
      user.getAttendanceByDate().then((result) => {
        if (
          (result.data.data.login == false) &
          (result.data.data.end_time.last_log == false)
        ) {
          swal
            .fire({
              title: "Would you like to start your day",
              showDenyButton: true,
              denyButtonText: `No`,
              confirmButtonText: "Yes",
              confirmButtonColor: "#32a852",
              denyButtonColor: "#f00",
              allowOutsideClick: false,
              icon: "question",
            })
            .then((result1) => {
              /* Read more about isConfirmed below */
              if (result1.isConfirmed) {
                var flag = "start";
                this.reqObj.flag = flag;
                user
                  .updateUser(this.reqObj)
                  .then((result) => {
                    this.reqObj.breakType = "";
                    this.reqObj.breakTime = null;
                    // this.TodayTimeSpent();
                    // this.getUserPermissions();
                    let modalContent = result.data;
                    // console.log(modalContent);
                    if (modalContent.data.show_modal) {
                      swal
                        .fire({
                          imageUrl: modalContent.data.applause
                            ? "img/clapping.gif"
                            : "img/sad2.gif",
                          imageHeight: 180,
                          imageWidth: 180,
                          title: modalContent.message,
                          html: modalContent.data.applause
                            ? ""
                            : '<b>Your are <span style="color:red">' +
                              modalContent.data["late count"] +
                              "</span> time late in this month</b>",
                          background: modalContent.data.applause
                            ? 'url("../img/pp.jpg") no-repeat center center/cover'
                            : "",
                          timer: 2500,
                          padding: "2rem",
                          showConfirmButton: false,
                        })
                        .then(() => {
                          if (Object.hasOwn(modalContent.data, "reward")) {
                            if (modalContent.data.reward.length > 0) {
                              this.rewards = modalContent.data.reward;
                              $("#userRewardModal").modal("show");
                              // setTimeout(() => {
                              //   $("#userRewardModal").modal("hide");
                              //   this.rewards=[];
                              // }, 2500);
                            }
                          }
                        });
                    }
                  })
                  .then(() => {
                    this.getUserPermissions();
                  });
                // this.$forceUpdate();
              }
            });
        }
        // else if (
        //   (result.data.data.login == false) &
        //   (result.data.data.end_time.last_log == true)
        // )
        // {
        //   swal
        //     .fire({
        //       title:
        //         "You forgot to stop your day. would you like to stop it ? ",
        //       showDenyButton: true,
        //       denyButtonText: `No`,
        //       confirmButtonText: "Yes",
        //       confirmButtonColor: "#32a852",
        //       denyButtonColor: "#f00",
        //     })
        //     .then((result2) => {
        //       if (result2.isConfirmed) {
        //         this.start_time = result.data.data.end_time.last_log_start;
        //         this.showDialog = true;
        //         // swal.fire({
        //         // title: "Confirm details?",
        //         // html:'<datepicker></datepicker>',
        //         // type: "warning",
        //         //     })
        //       }
        //     });
        // }
        else {
        }
      });
    },

    updateUser(flag, isValid) {
      if (isValid) {
        if (!this.reqObj.breakType) {
          alert("Please select any one options for break");
          return false;
        }
        if (!this.reqObj.breakTime) {
          alert("Please add valid time in minutes");
          return false;
        }
        if (this.reqObj.breakTime < 15) {
          alert("Break time can not be less than of 15 minute");
          return false;
        }
        if (this.reqObj.breakTime > 120) {
          alert("Break time can not be more than of 120 minute");
          return false;
        }
      }
      this.reqObj.flag = flag;
      user.updateUser(this.reqObj).then((result) => {
        this.reqObj.breakType = "";
        this.reqObj.breakTime = null;
        let modalContent = result.data;
        if (result.data.code == "422") {
          alert(result.data.message);
          window.location.reload();
        }
        if (modalContent.data.show_modal) {
          swal
            .fire({
              imageUrl: modalContent.data.applause
                ? "img/clapping.gif"
                : "img/sad2.gif",
              imageHeight: 180,
              imageWidth: 180,
              title: modalContent.message,
              html: modalContent.data.applause
                ? ""
                : '<b>Your are <span style="color:red">' +
                  modalContent.data["late count"] +
                  "</span> time late in this month</b>",
              background: modalContent.data.applause
                ? 'url("../img/pp.jpg") no-repeat center center/cover'
                : "",
              timer: 2500,
              padding: "2rem",
              showConfirmButton: false,
            })
            .then((res) => {
              if (Object.hasOwn(modalContent.data, "reward")) {
                if (modalContent.data.reward.length > 0) {
                  this.rewards = modalContent.data.reward;
                  $("#userRewardModal").modal("show");
                  // setTimeout(() => {
                  //   $("#userRewardModal").modal("hide");
                  //   this.rewards=[];
                  // }, 2500);
                }
              }
            });
        } else {
          if (Object.hasOwn(modalContent.data, "reward")) {
            if (modalContent.data.reward.length > 0) {
              this.rewards = modalContent.data.reward;
              $("#userRewardModal").modal("show");
              // setTimeout(() => {
              //   $("#userRewardModal").modal("hide");
              //   this.rewards=[];
              // }, 2500);
            }
          }
        }

        // if(!modalContent.data.applause){
        //   swal.fire({
        //     imageUrl:'img/sad2.gif',
        //     imageHeight:180,
        //     imageWidth:180,
        //     title:modalContent.message,
        //     padding:'2rem',
        //     timer:3500,
        //     showConfirmButton:false
        //   })
        // }
        // this.TodayTimeSpent()
        this.getUserPermissions();
      });
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.passwordState = valid;
      return valid;
    },
    changePasswdModalHandler() {
      $("#changePasswdModal").modal("show");
    },
    resetModal() {
      this.old_password = "";
      this.new_password = "";
      this.confirm_password = "";
      this.passwordError = "";
      this.passwordState = null;
    },

    handleOk(bvModalEvt) {
      // Trigger submit handler
      this.checkAndResetPassword();
    },

    // password validation
    passwordValidation() {
      let check = true;
      var regex =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
      this.passwordError = "";
      if (!regex.test(this.new_password)) {
        check = false;
        // this.passwordError = "Please enter valid password";
        this.passwordError =
          "Password must contain at least one numeric digit, one uppercase , one lowercase letter and length greater than or equal to 8";
      }
      return check;
    },

    checkAndResetPassword() {
      this.alert = "";
      if (this.new_password !== this.confirm_password) {
        this.alert = "New and Confirm password should match";
      } else {
        if (this.passwordValidation()) {
          user.getCurrentUser().then((result) => {
            var user_id = result.data.id;
            if (this.old_password != this.new_password) {
              user
                .checkAndResetPassword(
                  user_id,
                  this.new_password,
                  this.old_password
                )
                .then((result) => {
                  //shopw message from API
                  // alert("code",result.data.code)
                  // alert(result.data.message)
                  if (result.data.code) {
                    Swal.fire("Success", result.data.message, "success");
                    $("#changePasswdModal").modal("hide");
                  }
                });
            } else {
              Swal.fire("Error", "Old and New Password Same", "error");
            }
          });
        } else {
          this.passwordValidation();
        }
      }
    },
    openSearchDrawer() {
      $("#search-container").slideDown();
    },

    getCurrentUser() {
      user.getCurrentUser().then((result) => {
        this.current_user = result.data.id;
        this.img_url = result.data.image_url;
        //set user in store
        //this.setCurrentUser(result.data);
      });
    },
    logout() {
      user.getCurrentUser().then((result) => {
        if (result.data.state == null) {
          this.user_logout();
        } else {
          this.CheckUserPerDayMinutes();
        }
      });
    },
    user_logout() {
      this.logoutUser();
      this.$nextTick(() => {
        this.getCurrentUser();
      });
    },
    emailsettings() {
      this.$router.push({ name: "emailsettings" });
    },
    CheckUserPerDayMinutes() {
      user.CheckUserPerDayMinutes().then((result) => {
        if (result.data.code == 200) {
          if (result.data.data == true) {
            swal
              .fire({
                title: "Would you like to end your day",
                showDenyButton: true,
                denyButtonText: `No`,
                confirmButtonText: "Yes",
                confirmButtonColor: "#32a852",
                denyButtonColor: "#f00",
                icon: "question",
              })
              .then((result1) => {
                /* Read more about isConfirmed below */
                if (result1.isConfirmed) {
                  var flag = "stop";
                  this.reqObj.flag = flag;
                  user.updateUser(this.reqObj).then((result) => {
                    this.reqObj.breakType = "";
                    this.reqObj.breakTime = null;
                    this.user_logout();
                  });
                } else {
                  this.user_logout();
                }
              });
          } else {
            this.user_logout();
          }
        }
      });
    },
    LogOutAndStop() {
      const data = {};
      data.flag = "stop";
      user.updateUser(data).then((result) => {
        if (result.data.code == "422") {
          alert(result.data.message);
          window.location.reload();
        }
        this.user_logout();
      });
    },

    searchDrawer() {
      $("#search_box").slideToggle("slow");
    },

    searchDrawerclose() {
      $("#search_box").slideToggle("slow");
    },

    hideBirthdayQuoteModal() {
      this.AttendanceAlert();
    },
  },
  mounted() {
    this.getTodaysQuotes();
    this.getCurrentUser();
    $("#userRewardModal").on("hidden.bs.modal", this.hideModalEventHandler);
    $("#changePasswdModal").on("hidden.bs.modal", this.resetModal);
    $("#birthday-quotes-modal").on(
      "hidden.bs.modal",
      this.hideBirthdayQuoteModal
    );
    // $(window).resize(function () {
    //   if ($(window).width() <= 768) {
    //     $("#sideBar").removeClass("d-block");
    //     $("#sideBar").addClass("d-none");
    //   }
    //   else{
    //     $("#sideBar").removeClass("d-none");
    //     $("#sideBar").addClass("d-block");
    //   }
    // });
    // $('#sideBarDrop').click(function(){
    //   $("#sideBar").removeClass("d-block");
    //   $("#sideBar").addClass("d-none");
    //   $("#sideBarDrop").removeClass("d-block");
    //   $("#sideBarDrop").addClass("d-none");
    // });
    // $("#link").click(function(){
    //   $("#sideBar").removeClass("d-block");
    //   $("#sideBar").addClass("d-none");
    //   $("#sideBarDrop").removeClass("d-block");
    //   $("#sideBarDrop").addClass("d-none");
    // })
    // $("#userRewardModal").modal("show");
  },

  computed: {
    ...mapState("userStore", ["currentUser"]),
    ...mapState("common", ["currentPage"]),
    // toggleClass(){
    //   let width=window.screen.width;
    //   if(width<768){
    //     return "offcanvas position-fixed top-0 h-100"
    //   }
    //   else{
    //     return "position-fixed top-0 h-100"
    //   }
    // }
  },
};
</script>
<style scoped>
.notify::before {
  content: "";
  height: 8px;
  width: 8px;
  background-color: blue;
  /* right:12px; */
  margin-left: 10px;
  border-radius: 50%;
  position: absolute;
}

.dropdown-item {
  font-size: 12px;
  cursor: pointer;
}
</style>
